import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { rgba } from "polished";
import { Helmet } from "react-helmet";

import PageWrapper from "../components/PageWrapper";
import { Section, Title, Text, Box } from "../components/Core";
import { device } from "../utils";

import CaseList from "../sections/landing1/Testimonial";
import CTA from "../sections/case-study/CTA";
// @ts-expect-error false positive image import failure
import imgBrand from "../assets/image/case-studies/dacorum-borough-council-logo.png";
// @ts-expect-error false positive image import failure
import bgImage from "../assets/image/case-studies/hemel.jpeg";
// @ts-expect-error false positive image import failure
import imgCase2 from "../assets/image/jpeg/case-details-half-single.jpg";
// @ts-expect-error false positive image import failure
import imgCase3 from "../assets/image/jpeg/case-details-half-single-2.jpg";

const Quotes = styled.span`
  font-size: 3rem;
  font-weight: 700;
  margin: 0 10px;
  color: #f04037;
`;

const Header = styled(Title)`
  font-size: 4.5rem;
`;
const Quote = styled(Text)`
  font-size: 1.5rem;
`;
const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.8);
`;

const ULStyled = styled.ul`
  justify-content: space-between;
  list-style: none;
  margin: 0;
  padding-left: 0;
  margin-bottom: 10px;

  @media ${device.sm} {
    display: flex;
    flex-wrap: wrap;
  }

  li {
    &:nth-child(odd) {
      @media ${device.sm} {
        margin-right: 40px;
      }
    }
    color: #021d2d;
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: -0.66px;
    line-height: 50px;
    display: flex;

    &:before {
      content: "\f00c";
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      display: inline-block;
      font-size: 13px;
      width: 25px;
      height: 25px;
      background-color: ${({ theme }) => rgba(theme.colors.secondary, 0.1)};
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 500px;
      color: ${({ theme }) => theme.colors.secondary};
      position: relative;
      top: 0.8rem;
      margin-right: 0.2rem;
    }
  }
`;

const CaseStudyDetails = () => {
  return (
    <>
      <PageWrapper>
        <Helmet>
          <title>Dacorum Borough Council Case Study</title>
          <meta
            name="description"
            content="A leading supplier of ShopWatch &amp; TownLink radio systems, ShopSafe offer effective and affordable crime reduction radio schemes throughout the UK."
          />
        </Helmet>
        <Section
          backgroundImage={`url(${bgImage})`}
          backgroundPosition="center"
          backgroundSize="cover"
          backgroundRepeat="no-repeat"
          position="relative"
        >
          <Overlay />
          <div className="pt-5"></div>
          <Container>
            <Row className="justify-content-center text-center">
              <Col lg="8">
                <Box className="text-center" mb={4}>
                  <img src={imgBrand} alt="" className="img-fluid" />
                </Box>
                <Header variant="hero">Dacorum Borough Council</Header>
                <Quote>
                  <Quotes>"</Quotes>Our primary objective was to save money, and
                  of course to improve overall quality, resilience and
                  reliability<Quotes>"</Quotes>
                </Quote>
              </Col>
            </Row>
          </Container>
        </Section>
        <Section className="position-relative">
          <Container>
            <Row>
              <Col lg="12" xl="10" className="offset-xl-1">
                <Box pb={["40px", null, "65px"]}>
                  <Title variant="card" mb="18px">
                    Overview
                  </Title>
                  <Text variant="small">
                    Dacorum Borough Council had a long history of providing CCTV
                    services and monitoring the shopwatch system in Hemel
                    Hempstead. A move to a new location provided the opportunity
                    to take a radical rethink of their strategy; to break free
                    from the cost of rented fibre networks; expand the services
                    offered providing new cost effective solutions and generate
                    potential revenue streams.
                  </Text>
                </Box>
                <Box>
                  <Title variant="card" mb="18px">
                    About the Client
                  </Title>
                  <Text variant="small">
                    With a population of 150,000 Dacorum Council in West
                    Hertfordshire includes the major towns of Hemel Hempstead,
                    Berkhamsted and Tring. Whilst history abounds all around,
                    Hemel Hempstead was one of several ‘New Towns’ established
                    after WW2. Just 24 miles from the centre of London and
                    alongside the M1, there is a vibrant new tech feel to the
                    industry and businesses attracted to the town. The
                    historical towns of ‘Berko’ and Tring are quite different
                    and in the north the landscape flows into rich farmland
                    toward the Dunstable Downs.
                  </Text>
                </Box>
              </Col>
            </Row>
            {/* <Row>
              <Col lg="6" className="mb-lg-0 mb-30">
                <Box>
                  <img src={imgCase2} alt="" className="img-fluid" />
                </Box>
              </Col>
              <Col lg="6">
                <Box>
                  <img src={imgCase3} alt="" className="img-fluid" />
                </Box>
              </Col>
            </Row> */}
          </Container>
        </Section>
        <Section bg="#f6f6f8" py={0}>
          <Container>
            <Row className="pt-5">
              <Col lg="12" xl="10" className="offset-xl-1 pt-4">
                <Box pb={["40px", null, "65px"]}>
                  <Title variant="card" mb="18px">
                    The Challenges
                  </Title>
                  <ULStyled>
                    <li>Big Point 1</li>
                    <li>Big Point 2</li>
                    <li>Big Point 3</li>
                  </ULStyled>
                  <Text variant="small" mb={3}>
                    Redevelopment of their Civic Centre site provided the ideal
                    opportunity to reconsider the legacy of networks that had
                    grown over many years as ‘early adopters’ of CCTV and
                    shopwatch systems in the late 1990’s. Networks had grown
                    reliant on third party networks, and that in turn was
                    proving costly and limited the opportunity to introduce new
                    technologies.
                    <br />
                    <br />
                    Whilst the ShopWatch schemes had been a top-of-the-range
                    analogue solution under the Councils remit, a decision had
                    been made in 2011 to allow the town centre to organise their
                    own system to be monitored by CCTV. Their decision to
                    introduce a budget solution had proven to be problematic and
                    unreliable.
                  </Text>
                </Box>
              </Col>
            </Row>
          </Container>
        </Section>
        <Section py={1}>
          <Container>
            <Row className="mt-5 mb-5 pb-5">
              <Col lg="12" xl="10" className="offset-xl-1 pt-4">
                <Box>
                  <Title variant="card" mb="18px">
                    The Solution
                  </Title>
                  <Text variant="small">
                    Images throughout the borough were delivered to CCTV over
                    rented fibre from a number of providers. With spiralling
                    costs, this was proving to be both extremely expensive and
                    inflexible. Tackling the legacy of rented fibre, Dacorum
                    reviewed all the options, and decided to consider building
                    their own microwave network. The design they eventually
                    selected formed a ‘ring’ that allowed the data to flow in
                    either direction. In the unlikely event of a failure in any
                    leg of the network, the data would simply re-route to flow
                    the other way.
                    <br />
                    <br />
                    Resilient and cost effective, with more than adequate
                    capacity, additional services could be added around the
                    ring, breaking out for the ‘last-hop’ to the intended
                    location. ShopSafe’s proposal to build a local and wide area
                    radio solution over the microwave network was one such
                    service, installing multiple radio sites from the microwave
                    ring around the borough. Using the latest digital DMR
                    technology with IP connectivity, the existing town shopwatch
                    schemes in Hemel Hempstead and Grove Hill were then migrated
                    from the ineffective budget system, with two new shopwatch
                    schemes in Berkhampsted and Tring to follow. Using all five
                    sites together delivered a resilient wide-area scheme.
                  </Text>
                </Box>
              </Col>
            </Row>
          </Container>
        </Section>
        <Section bg="#f6f6f8" py={0}>
          <Container>
            <Row className="pt-5">
              <Col lg="12" xl="10" className="offset-xl-1 pt-4">
                <Box pb={["40px", null, "65px"]}>
                  <Title variant="card" mb="18px">
                    The Results
                  </Title>
                  <ULStyled>
                    <li>Big Point 1</li>
                    <li>Big Point 2</li>
                    <li>Big Point 3</li>
                  </ULStyled>
                  <Text variant="small">
                    ShopSafe’s solution delivered a fully featured digital radio
                    system with capacity to deliver both ‘local’ services
                    tailored for a single town or ‘wide-area’ groups operating
                    throughout the borough. Functionality included GPS
                    positioning, Panic Alarms, Lone Worker and Text messaging.
                    Within CCTV, PC software monitors the relevant user groups,
                    records all conversations and can stun and revive users.
                    <br />
                    <br />
                    ShopSafe were responsible for delivering the new solution
                    and undertaking training. Via ShopSafe’s 24 hour, 365 day
                    HelpDesk, the customers enjoy comprehensive next-day Service
                    Exchange and Batteries-for-Life. Working to a detailed
                    Service Level Commitment, ShopSafe bill the end user for
                    radio hire and monitoring fees - generating an income stream
                    to offset operator costs.
                    <br />
                    <br />
                    Whilst new users could be from any source, all of which
                    operate privately within their own group, the first was the
                    Councils own Refuse department - installing radios into
                    their fleet of lorries to replace an expensive cellular
                    radio link. With a separate PC management system, they
                    monitor their system entirely separately of the CCTV control
                    room.
                  </Text>
                </Box>
              </Col>
            </Row>
          </Container>
        </Section>
        <CaseList />
        <CTA />
      </PageWrapper>
    </>
  );
};
export default CaseStudyDetails;
